import React, { useEffect } from "react"
import LayoutComponent from "../components/LayoutComponent/LayoutComponent"
import MenuComponent from "../components/MenuComponent/MenuComponent"
import SEO from "../components/seo"
import "../styles/global.scss"
import { unloadRecaptcha } from "../utils/Captcha"

const Impressum = () => {
  useEffect(() => {
    unloadRecaptcha()
  }, [])

  return (
    <LayoutComponent isEnglish languageSwitchSubpage="impressum">
      <SEO title="Imprint" />
      <MenuComponent selected={0} isEnglish />
      <h2 className="underline-headline a-little-bit-of-margin-top">Imprint</h2>
      <p>
        MTV 1846 e.V. Ludwigsburg
        <br />
        Abteilung Rollerderby
        <br />
        Bebenhäuser Straße 41
        <br />
        71638 Ludwigsburg
      </p>
      <p>
        Abteilungsleiter: Miriam Wilezich, Florian Böpple
        <br />
        Kassenwart: Sören Schneemann
      </p>
      <p>
        Angaben gemäß § 5 TMG Männerturnverein
        <br />
        (MTV) 1846 e.V. Ludwigsburg
        <br />
        Bebenhäuser Str. 41
        <br />
        71638 Ludwigsburg
      </p>
      <p>
        Vereinsregister: VR 200262
        <br />
        Registergericht: Amtsgericht Stuttgart
      </p>
      <p>
        Vertreten durch den Vorstand:
        <br />
        Jochen Eisele (1. Vorsitzender)
        <br />
        Franz Weckesser (2. Vorsitzender)
        <br />
        Claus Jarmer (Schatzmeister)
      </p>
      <p>
        Kontakt
        <br />
        Telefon: 07141 – 911880
        <br />
        Telefax: 07141 – 9118811
        <br />
        Mail:{" "}
        <a href="mailto:info@mtv-ludwigsburg.de">info@mtv-ludwigsburg.de</a>
      </p>
      <p>
        Umsatzsteuer-ID
        <br />
        Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
        DE146121555
      </p>
      <p>
        Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV MTV 1846 e.V.
        Ludwigsburg, Bebenhäuser Str. 41, 71638 Ludwigsburg. Wir sind nicht
        bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle teilzunehmen.
      </p>

      <h2 className="underline-headline a-little-bit-of-margin-top smaller-headline">
        Copyright
      </h2>
      <p>
        The contents of this website are created with care. However, we do not
        assume liability for the accuracy, completeness and timeliness of the
        content of the contents provided.
      </p>

      <p>
        Texts, images, photos and films are generally protected by copyright.
        This applies even if no copyright notice is attached.
      </p>

      <h2 className="underline-headline a-little-bit-of-margin-top smaller-headline">
        Other
      </h2>
      <p className="left-side-margin a-little-bit-of-margin-top">
        This site is using PHP Mailer
      </p>

      <h2 className="underline-headline a-little-bit-of-margin-top smaller-headline">
        Technical realization
      </h2>
      <p className="left-side-margin a-little-bit-of-margin-top">
        <a
          href="https://www.devduck.de"
          target="_blank"
          rel="noopener noreferrer"
        >
          DevDuck
        </a>
      </p>
    </LayoutComponent>
  )
}

export default Impressum
